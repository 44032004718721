import { useAuthStore } from "@/stores/auth.store";
import i18n from "../i18n";

const API_HELPERS = {
  endpoints: {
    //backendUrl: "https://app.logiplanner.eu/api/",
    //websocketUrl: "https://app.logiplanner.eu",
     backendUrl: "https://app2.logiplanner.eu/api/",
     websocketUrl: "https://app2.logiplanner.eu",
    // backendUrl: "https://dev.logiplanner.eu/api/",
    // websocketUrl: "https://dev.logiplanner.eu",
    //app2
    adminDepartmentId: "0197e30e-d104-4a4c-ae7d-4e6148b965ab",
    //app2.
    //adminDepartmentId: "1984dcab-e7aa-4795-a91a-260e90841057",
    //dev.
    adminDepartmentId: "98d4a0e4-a84f-42ab-8fb1-74be4455d8fa",
    paymentUrl: "https://sandbox.przelewy24.pl/trnDirect",
    sentryDns: "https://26230cb93d4847118abd74186ec286d0@o4504241604526080.ingest.sentry.io/4504241616977920",
    //paymentUrl: "https://secure.przelewy24.pl/trnDirect",
  },
};

// ---------SECTIONS------------
//  AUTH
//  DEPARTMENTS
//  AUTHORIZATIONS
//  CARS
//  STOCK
//  CALENDAR
//  MONITARY
//  IMAGES
//  PAYMENT
//  WIDGETS
//  RATING
//  PHONE VERIFICATION
//  NOTIFICATIONS
//  CHATS
//  BLOCKING
//  FACTORING
//  DRIVERS
//  ADMIN
// ---------SECTIONS------------

const API_CODES = ["000", "001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "401", "403", "404"];

export default class ApiHelper {
  static returnBackendUrl() {
    return API_HELPERS.endpoints.backendUrl;
  }
  static returnWebsocketUrl() {
    return API_HELPERS.endpoints.websocketUrl;
  }
  static returnSentryDns() {
    return API_HELPERS.endpoints.sentryDns;
  }
  static returnAdminChatId() {
    return API_HELPERS.endpoints.adminDepartmentId;
  }

  //START AUTH
  static loginUrl() {
    return "auth/login";
  }
  static twoFactorAuth() {
    return "auth/two-factor-authentication";
  }
  static paymentUrl() {
    return API_HELPERS.endpoints.paymentUrl;
  }
  static registerUrl(id) {
    return `users/register${id}`;
  }
  static forgotPasswordUrl(email) {
    return `users/remind-password?email=${email}`;
  }
  static getGUS(nip) {
    return `companies/gus/${nip}`;
  }
  static getCheckTokenUrl() {
    return `auth/check-token`;
  }
  static resetPasswordUrl() {
    return `users/remind-password/new-password`;
  }
  static verifyAccountUrl() {
    return `auth/verification`;
  }
  static getRefreshTokenUrl() {
    return `auth/refresh-token`;
  }
  static getProfileUrl() {
    const authStore = useAuthStore();
    return `users/profile/${authStore?.user?.id}`;
  }
  static getCurrentUrl() {
    return API_HELPERS.endpoints.backendUrl + `users/current`;
  }
  static getUserCompaniesUrl() {
    return `companies/users/companies`;
  }

  static getCompany() {
    return `companies/`;
  }
  static getCompanyLeasings(id) {
    return `companies/leasigns/${id}`;
  }

  static updateCompany() {
    return `companies/update/`;
  }

  static checkCompanyDocuments(companyId) {
    return `companies/${companyId}/check-documents`;
  }

  static editCompanyType(companyId) {
    return `companies/update/${companyId}`;
  }

  static getChangePasswordUrl() {
    return `users/change-password`;
  }

  static getUpdateUserUrl() {
    return `users/update`;
  }

  static addToCompanyUrl() {
    return `companies/add-to-company`;
  }
  static removeFromCompany() {
    return `companies/remove-from-company`;
  }
  static getWorkers(id) {
    return `companies/users/${id}`;
  }
  static getWorkersNumber(id) {
    return `companies/${id}/workers-number`;
  }
  static getOwnersNumber(id) {
    return `companies/${id}/owners-number`;
  }
  static editWorkers(companyId, userId) {
    return `companies/${companyId}/users/${userId}`;
  }
  static getWorkerDepartments(id) {
    return `companies/users/${id}/departments`;
  }
  static getDocuments(id) {
    return `companies/documents/${id}`;
  }

  static editUserRole() {
    return `companies/change-role`;
  }

  static editUserStatus(companyId, userId) {
    return `companies/${companyId}/users/${userId}/change-status`;
  }

  static acceptInvitationUrl() {
    return `invitations/invitation/accept/`;
  }

  static rejectInvitationUrl() {
    return `invitations/invitation/reject/`;
  }
  static resendConfirmation() {
    return `users/register/send-confirm-registration-email/`;
  }

  static confirmRegistration() {
    return `users/register/confirm-registration/`;
  }
  static getUserRole(id) {
    return `companies/${id}/user-role`;
  }
  //END AUTH

  //START DEPARTMENTS
  static addDepartmentUrl() {
    return `departments/new-department`;
  }
  static fetchDepartmentsUrl(id, limit, page) {
    let params = "?";
    params += limit ? `limit=${limit}&` : "";
    params += page ? `page=${page}&` : "";
    return `departments/company/${id}${params}`;
  }
  static fetchDepartmentsUrlShort(id) {
    return `departments/names/companies/${id}`;
  }
  static fetchSingleDepartmentUrl(id) {
    return `departments/${id}`;
  }
  static fetchAllDepartmentsUrl(type, limit, page) {
    return `companies?type[0]=${type}&limit=${limit}&page=${page}`;
  }
  static deleteDepartmentUrl(id) {
    return `departments/delete-department/${id}`;
  }
  static editDepartmentUrl(id) {
    return `departments/update-department/${id}`;
  }
  static editContractTerms(id) {
    return `departments/${id}/contract-terms`;
  }
  static addWorkerToDepartment() {
    return `departments/add-to-department`;
  }
  static fetchWorkersFromDepartment(id) {
    return `departments/users/${id}`;
  }
  static removeWorkerFromDepartment() {
    return `departments/remove-from-department`;
  }
  static getBlocked(companyId, departmentId) {
    return `departments-block/companies/${companyId}/departments/${departmentId}`;
  }
  //END DEPARTMENTS

  //START AUTHORIZATIONS
  static authorizeDepartment() {
    return `departments-auth/add-to-auth`;
  }
  static listAuthorizations(id, status) {
    if (status === "ALL") return `departments-auth/auths/${id}`;
    return `departments-auth/auths/${id}?status=${status}`;
  }
  static listAuthorizationsForCompany(id, status) {
    if (status === "ALL") return `departments-auth/auths/company/${id}`;
    return `departments-auth/auths/company/${id}?status=${status}`;
  }
  static listAllAuthorizations(id, status) {
    return `departments-auth/auths/company/${id}?status=${status}`;
  }
  static listAllAuthorizationsCounter(id) {
    return `departments-auth/auths-number/company/${id}`;
  }
  static listAllAuthorizationsWithCompanies(id) {
    return `departments-auth/auths/companies/${id}`;
  }
  static acceptAuthorization(id) {
    return `departments-auth/accept/${id}`;
  }
  static rejectAuthorization(id) {
    return `departments-auth/reject/${id}`;
  }
  static retreatAuthorization(id) {
    return `departments-auth/retreat/${id}`;
  }
  static fetchOutsideDepartments(id) {
    return `departments/temp/companies/${id}`;
  }
  static addOutsideDepartment() {
    return `departments/temp`;
  }
  //END AUTHORIZATIONS

  //START CARS
  static listCars(id) {
    return `cars/my-department/${id}`;
  }
  static listCarsCompany(id) {
    return `cars/companies/${id}`;
  }
  static listCarsForDepartmentUrl(departmentId) {
    return `cars/departments/${departmentId}/short`;
  }
  static listInsurers(id) {
    return `cars/insurance-policies/${id}`;
  }
  static listInsurersForCompany(id) {
    return `cars/insurance-policies/companies/${id}`;
  }
  static addCar() {
    return `cars/new-car`;
  }
  static updateCar() {
    return `cars/update-car`;
  }
  static removeCar(id) {
    return `cars/remove-car/${id}`;
  }
  static checkType(companyId, carType) {
    return `cars/check-car-access/${companyId}/${carType}`;
  }
  static addCarPolicy(carId) {
    return `cars/${carId}/policy`;
  }
  //END CARS

  // START STOCK
  static getSingleAuction(id) {
    return `auctions/${id}`;
  }
  static getSingleAuctionShort(id) {
    return `auctions/short-details/${id}`;
  }
  static getSingleAuctionOffer(auctionOfferId) {
    return `auctions/offers/details/${auctionOfferId}`;
  }
  static getAllAuctions() {
    return `auctions/all`;
  }
  static getCountedAuctionsUrl() {
    return `auctions/number`;
  }
  static getProducerAuctionsCounter(companyId) {
    return `auctions/factories/${companyId}/count`;
  }
  static getDriverAuctionsCounter(companyId) {
    return `auctions/carriers/${companyId}/count`;
  }
  static getProducerTransportsCounter(companyId) {
    return `auctions/carriers/${companyId}/count-transports`;
  }
  static getAllMyAuctionOffers(id) {
    return `auctions/offers/company/${id}`;
  }
  static createAuction() {
    return `auctions/new`;
  }
  static addAuctionFiles() {
    return `auctions/add-files`;
  }
  static createAuctionOffer() {
    return `auctions/offers/new-offer`;
  }
  static getCompanyAuctionDiary(companyId) {
    return `auctions/${companyId}/transactions-log`;
  }
  static getAuctionsMadeByCompany(companyId) {
    return `auctions/${companyId}/offers`;
  }
  static getAuctionsCounter(companyId) {
    return `auctions/factories/${companyId}/count-created`;
  }
  static getAuctionsMadeByCompanyIds(companyId) {
    return `auctions/id/companies/${companyId}`;
  }
  static getCompanySurcharges(companyId) {
    return `auctions/${companyId}/surcharge`;
  }
  static getFinishedAuctions(companyId) {
    return `auctions/${companyId}/finished`;
  }
  static getGivenTransports(companyId) {
    return `auctions/${companyId}/won`;
  }
  static getAuctionChangeLog(auctionId) {
    return `auctions/${auctionId}/changelog`;
  }
  static getCountedAuctionChangeLogUrl(auctionId) {
    return `auctions/${auctionId}/changelog-number`;
  }
  static getAuctionArchive(companyId) {
    return `auctions/archive/companies/${companyId}`;
  }
  static getAllTransactions(companyId) {
    return `auctions/offers/${companyId}/lowest`;
  }
  static removeAuctionOffer(id) {
    return `auctions/offers/remove-offer/${id}`;
  }
  static editAuctionOffer(id) {
    return `auctions/offers/update/${id}`;
  }
  static editAuction(id) {
    return `auctions/${id}`;
  }
  static showAllOfferWithFilterStatus(status, auctionId) {
    return `auctions/offers/${auctionId}`;
  }
  static markAuctionAsRead(auctionId) {
    return `auctions/mark-as-read/${auctionId}`;
  }
  static renameAuctionTemplate(auctionId) {
    return `auctions/${auctionId}/templates`;
  }
  static markAuctionAsUnread(auctionId) {
    return `auctions/mark-as-unread/${auctionId}`;
  }
  static changeOfferStatus(offerId) {
    return `auctions/offers/change-status/${offerId}`;
  }
  static changeAuctionStatus(auctionId) {
    return `auctions/${auctionId}/change-status`;
  }
  static getAllDepartmentsWhoPlacedOffer(departmentId) {
    return `auctions/departments/${departmentId}`;
  }
  static getAllDepartmentsWhoPlacedOfferForCompany(companyId) {
    return `auctions/companies/${companyId}`;
  }
  static showCertainOffersForAuctionInCompany(companyId, auctionId) {
    return `auctions/offers/auction/${auctionId}/company/${companyId}`;
  }
  static makeReservations() {
    return `monitory-notes/auctions`;
  }
  static createGroup() {
    return `groups/new-group`;
  }
  static sendInvitationToGroup() {
    return `groups/invitations`;
  }
  static withdrawInvitationToGroup(invitationId) {
    return `groups/invitations/${invitationId}/withdraw`;
  }
  static checkNip(nip) {
    return `companies/check-nip/${nip}`;
  }
  static assignToGroup() {
    return `groups/assign`;
  }
  static removeFromGroup() {
    return `groups/remove-from-group`;
  }
  static editGroup() {
    return `groups/edit-group`;
  }
  static getGroups(departmentId) {
    return `groups/my-groups/${departmentId}`;
  }
  static getGroupsCompany(companyId) {
    return `groups/companies/${companyId}`;
  }
  static deleteGroup(departmentId, groupId) {
    return `groups/delete-group/${departmentId}/${groupId}`;
  }
  static getGroupMembers(departmentId, groupId) {
    return `groups/group-members/${departmentId}/${groupId}`;
  }
  static downloadAuctionDocument(auctionId) {
    return `auctions/${auctionId}/document`;
  }
  static getAuctionsBlueprint(companyId) {
    return `auctions/templates/companies/${companyId}`;
  }
  static getAuctionDetailsForCMR(auctionToken) {
    return `auctions/tokens/${auctionToken}`;
  }
  static getAuctionDetailsForCMROneOperation(auctionToken, loadingId, unloadingId) {
    return `auctions/tokens/${auctionToken}/loadings/${loadingId}/unloadings/${unloadingId}`;
  }
  static sendSignature(auctionToken) {
    return `auctions/transport-signature/${auctionToken}`;
  }
  static getCMR(transportId) {
    return `auctions/transports/${transportId}/e-cmr`;
  }
  static downloadAuctionTransportDocument(fileId) {
    return `auctions/${fileId}/order-document`;
  }
  static downloadCMRDocument(fileId) {
    return `auctions/transports/e-cmr/${fileId}`;
  }
  static reportContractTerms(id) {
    return `departments/${id}/report-contract-terms`;
  }
  static checkChangelogUnreadStatus(id) {
    return `auctions/${id}/unread-changelog-number`;
  }
  static getMonitoryNotesForAuction(id) {
    return `monitory-notes/auctions/${id}`;
  }
  // END STOCK

  //START CALENDAR
  static addMonthlyCalendar() {
    return `calendar`;
  }
  static getCalendarMonthly(departmentId, monthId, year) {
    return `calendar/month/${year}/${monthId}/departments/${departmentId}?limit=31`;
  }
  static removeTimeWindow(id) {
    return `calendar/delete/${id}`;
  }
  static updateTimeWindows(id) {
    return `calendar/update/${id}`;
  }
  static listTimeWindows(dayId, departmentId) {
    return `calendar/day/${dayId}/${departmentId}?limit=999999999999999`;
  }
  static checkIfDayFree(dayId, departmentId) {
    return `calendar/free-availabilities/departments/${departmentId}/date/${dayId}`;
  }
  static requestDayFill() {
    return `calendar/availabilities-request`;
  }
  static requestMonthFill() {
    return `calendar/calendar-request`;
  }
  static copyCalendar() {
    return `calendar/copy`;
  }
  static getAvailableTimewindowsFor6Months(departmentId) {
    return `calendar/free-days/departments/${departmentId}`;
  }
  static getAvailableTimewindowsForADay(departmentId, dayId, carrierId) {
    return `calendar/day/free-availability/${dayId}/departments/${departmentId}/carriers/${carrierId}`;
  }
  static getAvailableTimewindowsForADayForRamp(rampId, dayId) {
    return `calendar/ramps/${rampId}/day/${dayId}/free-availabilities`;
  }
  static addRamps() {
    return `ramps`;
  }
  static deleteRamp(rampId, departmentId) {
    return `ramps/${rampId}/departments/${departmentId}`;
  }
  static getRamps(departmentId) {
    return `ramps/departments/${departmentId}`;
  }
  static getCalendarMonthlyForRamp(rampId, yearId, monthId) {
    return `calendar/month/${yearId}/${monthId}/ramps/${rampId}`;
  }
  static listDailyTimeWindowsForRamp(dayId, rampId) {
    return `calendar/day/${dayId}/ramps/${rampId}`;
  }
  static updateRampDailyTimeWindows(rampId) {
    return `calendar/update/ramps/${rampId}`;
  }
  static getAvailableRampsForHourAndDay(departmentId, date, startHour, endHour) {
    return `ramps/departments/${departmentId}/date/${date}/startHour/${startHour}/endHour/${endHour}`;
  }
  static getAvailableRampsForDay(departmentId, date) {
    return `ramps/departments/${departmentId}/date/${date}/available`;
  }

  //END CALENDAR

  //START MONITARY
  static addReservation() {
    return `monitory-notes/new-monitory-note`;
  }
  static acceptMonitaryStatusChange(monitoryNoteId) {
    return `monitory-notes/${monitoryNoteId}/accept`;
  }
  static revokeMonitaryStatusChange(monitoryNoteId) {
    return `monitory-notes/${monitoryNoteId}/re-accept`;
  }
  static rejectMonitaryStatusChange(monitoryNoteId) {
    return `monitory-notes/${monitoryNoteId}/reject`;
  }
  static finishMonitaryStatusChange(monitoryNoteId) {
    return `monitory-notes/finish/${monitoryNoteId}`;
  }
  static acceptMonitary(id) {
    return `monitory-notes/commence/${id}`;
  }
  static finishMonitary(id) {
    return `monitory-notes/finish/${id}`;
  }
  static listReservations(id) {
    return `monitory-notes/${id}`;
  }
  static getCountedWaitingMonitoriesUrl(departmentId) {
    return `monitory-notes/${departmentId}/number`;
  }
  static listMointaryByBompanyId(companyId) {
    return `monitory-notes/companies/${companyId}`;
  }
  static checkByDay(departmentId, day) {
    return `monitory-notes/departments/${departmentId}/date/${day}`;
  }
  static checkCompanyByDay(companyId, day) {
    return `monitory-notes/companies/${companyId}/date/${day}`;
  }
  static listStatuses(id) {
    return `monitory-notes/car-status/companies/${id}`;
  }
  static changeMonitaryStatus(id) {
    return `monitory-notes/change-status/${id}`;
  }
  static editMonitary(id) {
    return `monitory-notes/update/${id}`;
  }
  static downloadDocument(departmentId, monitaryId) {
    return `monitory-notes/monitory-note-document/${departmentId}/${monitaryId}`;
  }
  static listDetails(id, departmentId) {
    return `monitory-notes/monitory-note/${departmentId}/${id}`;
  }
  static listAll(id) {
    return `companies/monitory-notes-number/company/${id}`;
  }
  static listAllNumber(companyType) {
    return `monitory-notes/companies/types/${companyType}`;
  }
  static sendReminder(id) {
    return `monitory-notes/${id}/send-reminder`;
  }
  //END MONITARY

  //START IMAGES
  static getUploadProfileImageUrl(id = 0) {
    if (!id) return API_HELPERS.endpoints.backendUrl + `cdn/upload`;
    return API_HELPERS.endpoints.backendUrl + `cdn/upload/${id}`;
  }

  static removeProfileImageUrl(id) {
    return API_HELPERS.endpoints.backendUrl + `images/${id}`;
  }
  static getImage(id) {
    return API_HELPERS.endpoints.backendUrl + `cdn/files/${id}`;
  }
  static deletePicture(id) {
    return API_HELPERS.endpoints.backendUrl + `cdn/delete/${id}`;
  }
  // END IMAGES

  //START PAYMENT
  static fetchPriceList() {
    return `payments/payment-price-list`;
  }
  static fetchPayments(companyId, status) {
    return `payments/${companyId}`;
  }
  static newPayment(companyId) {
    return `payments/new-payment/${companyId}`;
  }
  static sessionStatus(sessionId) {
    return `payments/status/${sessionId}`;
  }
  static downloadInvoice(paymentId, companyId) {
    return `payments/invoice/${paymentId}/download/${companyId}`;
  }
  static downloadSummaryInvoice(id) {
    return `invoices/${id}/summary`;
  }
  static getSummaryInvoices(companyId) {
    return `invoices/summary/companies/${companyId}`;
  }
  static getAllInvoices(companyId) {
    return `payments/companies/${companyId}`;
  }
  //END PAYMENT

  //START WIDGETS
  static getAllWidgets(account, tab) {
    return `widgets/${account}?tab=${tab}`;
  }
  static getUserWidgets(tab) {
    return `widgets/users?tab=${tab}`;
  }
  static updateWidgets() {
    return `widgets/users`;
  }
  //END WIDGETS

  //START RATING
  static rateAuction() {
    return `ratings`;
  }
  static updateAuctionRating(id) {
    return `ratings/${id}`;
  }
  static getAuctionsToRate(id) {
    return `auctions/not-rated/companies/${id}`;
  }
  static getCompanyRatings(id) {
    return `ratings/companies/${id}`;
  }

  //END RATING

  //START PHONE VERIFICATION
  static getNumbers() {
    return `users/phone-number/not-verified`;
  }
  //END PHONE VERIFICATION

  //START NOTIFICATIONS
  static getNotificationsNumber(companyId) {
    return `notifications/user/companies/${companyId}/number`;
  }
  static getNotifications(companyId) {
    return `notifications/user/companies/${companyId}`;
  }
  static markAsRead(id, companyId) {
    return `notifications/${id}/companies/${companyId}/mark-read`;
  }
  static markAllAsRead(companyId) {
    return `notifications/companies/${companyId}/mark-all-read`;
  }
  static markAsUnread(id, companyId) {
    return `notifications/${id}/companies/${companyId}/mark-unread`;
  }
  //END NOTIFICATIONS

  //START CHATS
  static getChatList(id) {
    return `chats/${id}`;
  }
  static getAllCompanyChats(id) {
    return `chats/companies/${id}`;
  }
  static getChatDetails(departmentId, chatId) {
    return `chats/${departmentId}/${chatId}`;
  }
  static getMessages(id) {
    return `chats/${id}/messages`;
  }
  static getUnseen() {
    return `chats/show-unseen-chats-number`;
  }
  static showAttachment(id) {
    return `chats/messages/${id}`;
  }
  static postAttachment() {
    return `chats/messages/send-attachment`;
  }
  //END CHATS

  //START BLOCKING
  static blockDepartment(departmentId) {
    return `departments-block/block/departments/${departmentId}`;
  }

  static unblockDepartment(departmentId) {
    return `departments-block/unblock/departments/${departmentId}`;
  }
  static listBlockedDepartments(departmentId) {
    return `departments-block/departments/${departmentId}`;
  }
  //END BLOCKING

  //START FACTORING
  static addFactoring(companyId) {
    return `admins/companies/${companyId}` + "/add-factoring";
  }
  static deleteFactoring(companyId) {
    return `admins/companies/${companyId}` + "/delete-factoring";
  }
  static changeCommission() {
    return `admins/change-factoring-commission`;
  }
  static fetchAuctionsWithFactoringAdmin() {
    return `admins/auctions/factoring`;
  }
  static fetchAuctionsWithFactoring(companyId) {
    return `auctions/companies/${companyId}/factoring`;
  }
  static checkFactoring(companyId) {
    return `companies/${companyId}/check-factoring`;
  }
  //END FACTORING

  //START DRIVERS
  static addDriver() {
    return `drivers`;
  }
  static listDrivers(departmentId) {
    return `drivers/departments/${departmentId}`;
  }
  static listDriversForCompany(companyId) {
    return `drivers/companies/${companyId}`;
  }
  static addDriverToDepartments(driverId) {
    return `drivers/${driverId}/departments/add`;
  }
  static removeDriverFromDepartments(driverId) {
    return `drivers/${driverId}/departments/delete `;
  }
  static editDriver(driverId) {
    return `drivers/${driverId}`;
  }
  static deleteDriver(driverId) {
    return `drivers/${driverId}`;
  }

  //END DRIVERS

  //START ADMIN
  static getAdminDepartment() {
    return "admins/departments";
  }
  static getAdminNotifications() {
    return "notifications/admins";
  }
  static getAdminNotificationsCounter() {
    return "notifications/admins/number";
  }
  static getAdminUsersLogiplannerUrl() {
    return `admins/users`;
  }
  static getCountedLogiplannerUsersUrl() {
    return `admins/users-number`;
  }
  static getAdminCompaniesLogiplannerUrl() {
    return `admins/companies`;
  }
  static getCountedLogiplannerWaitingCompaniesUrl() {
    return `admins/companies-number`;
  }
  static getAdminChangeUserStatusUrl() {
    return `admins/statuses`;
  }
  static updateUserNote(id) {
    return `admins/companies/${id}`;
  }
  static getAdminWorkers() {
    return `admins/show-admins`;
  }
  static getAdminDepartments() {
    return `departments`;
  }
  static addAdminWorker() {
    return `admins/add-admin`;
  }
  static getAdminChartInfo() {
    return `admins/chart-info`;
  }
  static getAdminUserChartInfo() {
    return `admins/chart-users`;
  }
  static getAdminInvoices() {
    return `admins/invoices`;
  }
  static getAdminPrices() {
    return `payments/payment-price-list`;
  }
  static getAdminLeasings() {
    return `admins/companies/leasigns`;
  }
  static getCountedAdminLeasingsUrl() {
    return `admins/leasings-number`;
  }
  static getTypesNumber() {
    return `admins/type-number`;
  }

  static addPricePackage() {
    return `admins/payments/new-item`;
  }
  static deletePricePackage(id) {
    return `admins/payments/delete/${id}`;
  }
  static getMonitoryNotes() {
    return `admins/monitory-notes`;
  }
  static getCountedMonitoryNotesUrl() {
    return `admins/monitory-notes-number`;
  }
  static getPayments() {
    return `admins/payments`;
  }
  static sendGroupMessage() {
    return `admins/multiple-messages`;
  }
  static getAllChats() {
    return `admins/chats`;
  }
  static getAllChatsMessages() {
    return `admins/chats/messages`;
  }
  static getChatMessages(id) {
    return `admins/chats/${id}`;
  }
  static changeLeasingStatus() {
    return `admins/companies/leasigns`;
  }
  static fetchRaports() {
    return `admins/logs`;
  }
  static fetchActionTypes() {
    return `admins/logs/action-types`;
  }
  static fetchOneUser(id) {
    return `admins/user-details/${id}`;
  }
  static fetchAuctions() {
    return `admins/auctions`;
  }
  static fetchAdverts() {
    return `advertisements`;
  }
  static deleteAdvert(id) {
    return `advertisements/${id}`;
  }
  static fetchDocsToVerify() {
    return `admins/companies/files`;
  }
  static changeDocStatus(fileId) {
    return `admins/files/${fileId}/status`;
  }
  static getVerificationTransfers() {
    return `admins/companies/verifications-transfers`;
  }
  static verifyCompany(id) {
    return `admins/companies/${id}/verifications-transfers`;
  }
  static getAdminCompanyId() {
    return `admins/companies/admin-company`;
  }
  //END ADMIN
  static getResponseCodes() {
    return API_CODES;
  }
  static getResponseMessage(code) {
    const { t } = i18n.global;
    if (API_CODES.includes(code)) {
      return t("error_codes." + code);
    } else {
      return t("error_codes.default");
    }
  }
}
